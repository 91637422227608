import { ref } from 'vue'

export function useResize() {
  const windowWidth = ref(window.innerWidth)
  const windowHeight = ref(window.innerHeight)

  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth
    windowHeight.value = window.innerHeight
  })

  return {
    windowWidth,
    windowHeight
  }
}
