// // toastService.js
import { AnchorHTMLAttributes, reactive, toRefs } from 'vue'

const state = reactive({
  snackbar: false,
  text: '',
  color: '',
  timeout: 5000,
  btnText1: '' as string | null,
  callbackBtn1: null as (() => void) | null,
  loading1: false,
  btnText2: '' as string | null,
  callbackBtn2: null as (() => void) | null,
  loading2: false,
  closeBtn: true,
  location: 'center bottom' as string
})

export const useToast = () => {
  /**
   * @param message
   * @param options
   */
  const showToast = (
    message: string,
    options?: {
      color: string
      timeout: number
      btnText1?: string
      callbackBtn1?: () => void
      loading1?: boolean | null
      btnText2?: string | null
      callbackBtn2?: () => void
      loading2?: boolean
      closeBtn?: boolean
      location?: string
    }
  ) => {
    state.text = message
    state.color = options?.color || 'success'
    state.timeout = options?.timeout || 5000
    state.snackbar = true
    state.btnText1 = options?.btnText1 || null
    state.callbackBtn1 = options?.callbackBtn1 || null
    state.loading1 = options?.loading1 || false
    state.btnText2 = options?.btnText2 || null
    state.callbackBtn2 = options?.callbackBtn2 || null
    state.loading2 = options?.loading2 || false
    state.closeBtn = options?.closeBtn || true
    state.location = options?.location || 'center bottom'
  }

  const setLoading1 = (loading: boolean) => {
    state.loading1 = loading
  }

  const setLoading2 = (loading: boolean) => {
    state.loading2 = loading
  }

  return {
    ...toRefs(state),
    showToast,
    setLoading1
  }
}

export const toastState = toRefs(state)
