export const metaDatas = {
  '404': {
    title: "Page non trouvée | Click N'Zen",
    description:
      "La page que vous cherchez n'existe pas. Retournez à l'accueil pour continuer votre navigation.",
    index: false
  },
  home: {
    title:
      "Click N'Zen | La Plateforme Juridique pour les Avocats et les Particuliers",
    description:
      "Click N'Zen, la plateforme juridique pour les avocats et les particuliers. Créez, gérez et signez vos actes juridiques en ligne.",
    index: true
  },
  faq: {
    title: "Foire Aux Questions | Click N'Zen",
    description:
      "Consultez notre foire aux questions pour obtenir des réponses à vos interrogations. Contactez-nous pour plus d'informations.",
    index: true
  },
  about: {
    title: "À Propos | Click N'Zen",
    description:
      "Découvrez Click N'Zen, la plateforme juridique pour les avocats et les particuliers. Contactez-nous pour plus d'informations.",
    index: true
  },
  contact: {
    title: "Contactez-Nous | Click N'Zen",
    description:
      "Contactez Click N'Zen pour obtenir des informations supplémentaires. Notre équipe est à votre écoute pour répondre à vos questions.",
    index: true
  },
  login: {
    title: "Connexion utilisateur | Click N'Zen",
    description:
      "Accédez à votre espace personnel sur Click N'Zen. Connectez-vous pour profiter de nos services exclusifs.",
    index: true
  },
  register: {
    title: "Créez votre compte | Click N'Zen",
    description:
      "Rejoignez Click N'Zen dès aujourd'hui. Inscrivez-vous facilement pour bénéficier de nos offres et services.",
    index: true
  },
  pricing: {
    title: "Nos Offres | Click N'Zen",
    description:
      "Découvrez nos offres et services sur Click N'Zen. Choisissez l'abonnement qui vous convient.",
    index: true
  },
  'lawyer-update-password': {
    title: "Mise à Jour Mot de Passe Avocat | Click N'Zen",
    description:
      "Mettez à jour votre mot de passe avocat sur Click N'Zen. Accédez à vos documents en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/home': {
    title: "Accueil Avocat | Tableau de Bord | Click N'Zen",
    description:
      'Bienvenue dans votre espace avocat. Consultez vos notifications, mises à jour et activités récentes.',
    index: false
  },
  'dashboard/lawyer/account-validation': {
    title: "Validation de Votre Compte Avocat | Click N'Zen",
    description:
      "Validez votre compte avocat pour accéder à toutes les fonctionnalités et services dédiés sur Click N'Zen.",
    index: false
  },
  'dashboard/lawyer/products': {
    title: "Vos Actes Juridiques | Click N'Zen",
    description:
      "Consultez et gérez vos actes juridiques créés sur Click N'Zen. Accédez à vos documents en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/agreement/create': {
    title: 'Création d"Acte Juridique | Click N\'Zen',
    description:
      "Créez facilement des actes juridiques sur Click N'Zen. Utilisez nos outils pour simplifier vos démarches.",
    index: false
  },
  'dashboard/lawyer/customers': {
    title: "Vos Clients | Click N'Zen",
    description:
      "Gérez vos clients et leurs informations sur Click N'Zen. Accédez à vos dossiers et documents en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/customers/{ID}': {
    title: "Détails Client | Click N'Zen",
    description:
      "Consultez les informations et documents de votre client sur Click N'Zen. Accédez à vos dossiers en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/invoices': {
    title: "Vos Factures | Click N'Zen",
    description:
      "Consultez et gérez vos factures sur Click N'Zen. Accédez à vos documents en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/invoices/{ID}': {
    title: "Détails Facture | Click N'Zen",
    description:
      "Consultez les détails de votre facture sur Click N'Zen. Accédez à vos documents en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/agreements': {
    title: "Vos Actes Juridiques | Click N'Zen",
    description:
      "Consultez et gérez vos actes juridiques sur Click N'Zen. Accédez à vos documents en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/agreements/{ID}': {
    title: "Détails Acte Juridique | Click N'Zen",
    description:
      "Consultez les détails de votre acte juridique sur Click N'Zen. Accédez à vos documents en toute sécurité.",
    index: false
  },
  'dashboard/lawyer/agreements/{ID}/edit': {
    title: "Modification Acte Juridique | Click N'Zen",
    description:
      "Modifiez votre acte juridique sur Click N'Zen. Utilisez nos outils pour simplifier vos démarches.",
    index: false
  },
  'dashboard/lawyer/account': {
    title: "Votre Compte Avocat | Click N'Zen",
    description:
      "Gérez vos informations personnelles et paramètres de compte sur Click N'Zen. Accédez à vos documents en toute sécurité.",
    index: false
  }
}
