import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { createVuetify } from 'vuetify'
import { fr } from 'vuetify/locale'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#89C37C',
          secondary: '#ff4081',
          accent: '#22425e',
          error: '#e85858',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        }
      }
    }
  },
  locale: {
    locale: 'fr',
    fallback: 'fr',
    messages: { fr }
  }
})

import './assets/styles/tailwind.css'
import './assets/styles/variables.css'
import './assets/styles/styles.scss'
import './assets/styles/fontawesome.css'

const app = createApp(App)

app.config.compilerOptions.isCustomElement = (tag) => tag === 'dotlottie-player'

app.use(router)
app.use(store)
app.use(vuetify)
app.component('QuillEditor', QuillEditor)
app.use(VueReCaptcha, {
  siteKey: '6LclaHkpAAAAAHJboHLbN3pBSsrLOTjqTLS3AunG',
  loaderOptions: {}
})

router.isReady().then(() => {
  app.mount('#app')
})
