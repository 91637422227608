import { createStore } from 'vuex'
import { useAxios } from '@/composables/useAxios'
import { useToast } from '@/services/toastService'
import { Lawyer } from '@/models/Lawyer'

const { showToast } = useToast()
const { apiClient } = useAxios()

export default createStore({
  state: {
    isDashboardLoading: false,
    isCheckout: false,
    isDashboard: false,
    isLogged: false,
    lawyerData: null as Lawyer | null,
    fullPath: '',
    screenWidth: window.innerWidth
  },
  getters: {
    getIsDashboardLoading: (state) => state.isDashboardLoading,
    getIsCheckout: (state) => state.isCheckout,
    getIsDashboard: (state) => state.isDashboard,
    getIsLogged: (state) => state.isLogged,
    getLawyerData: (state) => state.lawyerData,
    getScreenWidth: (state) => state.screenWidth
  },
  mutations: {
    SET_DASHBOARD_LOADING(state, value) {
      state.isDashboardLoading = value
    },
    SET_IS_CHECKOUT(state, value) {
      state.isCheckout = value
    },
    SET_IS_DASHBOARD(state, value) {
      state.isDashboard = value
    },
    SET_IS_LOGGED(state, value) {
      state.isLogged = value
    },
    SET_LAWYER_DATA(state, value) {
      state.lawyerData = value
    },
    SET_LAWYER_DATA_FIELD(
      state,
      { field, value }: { field: keyof Lawyer; value: any }
    ) {
      if (state.lawyerData === null || state.lawyerData === undefined) return

      state.lawyerData[field] = value as never
    },
    SET_LAWYER_DATA_FIELD_SEGMENT(state, { field, value }) {
      // Diviser le champ en segments, par exemple "details.civility" en ["details", "civility"]
      const fields = field.split('.')
      let target: any = state.lawyerData

      // Naviguer dans les niveaux de l'objet jusqu'à l'avant-dernier champ
      for (let i = 0; i < fields.length - 1; i++) {
        target = target[fields[i]]
      }

      // Mettre à jour la dernière propriété
      target[fields[fields.length - 1]] = value
    },
    SET_LAWYER_SOCIETY_DATA(state, value) {
      if (state.lawyerData === null || state.lawyerData === undefined) return

      state.lawyerData.society = value
    },
    UPDATE_IS_PRODUCT_DEFINED(state, value) {
      if (
        state.lawyerData === null ||
        state.lawyerData === undefined ||
        state.lawyerData.stripe === undefined
      )
        return

      state.lawyerData.stripe.isProductDefined = value
    },
    UPDATE_LAWYER_LOGO(state, logoUrl) {
      if (state.lawyerData === null || state.lawyerData === undefined) return

      state.lawyerData.details.logoUrl = logoUrl
    },
    UPDATE_SOCIETY_LOGO(state, logoUrl) {
      if (state.lawyerData === null || state.lawyerData === undefined) return

      const lawyerData = state.lawyerData as Lawyer

      if (lawyerData.society === undefined) return

      lawyerData.society.logoUrl = logoUrl
    },
    SET_SCREEN_WIDTH(state, width) {
      state.screenWidth = width

      if (
        (width >= 1080 && state.fullPath.includes('/dashboard')) ||
        (width >= 1280 && state.fullPath.includes('/checkout'))
      ) {
        document.documentElement.classList.add('tw-overflow-hidden')
      } else {
        document.documentElement.classList.remove('tw-overflow-hidden')
      }
    },
    SET_FULL_PATH(state, path) {
      state.fullPath = path
    }
  },
  actions: {
    setOverflowHidden({ state }, { to, width }) {
      if (
        (width >= 1080 && to.includes('/dashboard')) ||
        (width >= 1280 && to.includes('/checkout'))
      ) {
        document.documentElement.classList.add('tw-overflow-hidden')
      } else {
        document.documentElement.classList.remove('tw-overflow-hidden')
      }
    },

    async getLawyerData({ commit, state, dispatch }, { force = false } = {}) {
      if (state.lawyerData !== null && !force) {
        return state.lawyerData
      }

      try {
        const response = await apiClient.get('/dashboard/user', {
          params: {
            access_token: localStorage.getItem('access_token')
          }
        })

        if (!response || !response.data) {
          throw new Error('api.error.lawyer.data.not_found')
        }

        const lawyerData = response.data as Lawyer
        lawyerData.details.logoUrl =
          lawyerData.details.logoUrl !== null
            ? `${process.env.VUE_APP_API_URL_PUBLIC}${lawyerData.details.logoUrl}`
            : null

        if (
          lawyerData.society !== undefined &&
          lawyerData.society.members &&
          lawyerData.society.members?.totalItems > 0
        ) {
          try {
            Object.values(lawyerData.society.members?.items).forEach(
              (member: any) => {
                member.logoUrl =
                  member.logoUrl !== null
                    ? `${process.env.VUE_APP_API_URL_PUBLIC}${member.logoUrl}`
                    : null
                member.googleAvatarUrl =
                  member.googleAvatar !== null ? member.googleAvatar : null
              }
            )
          } catch (error) {
            console.log(error)
          }

          lawyerData.society.logoUrl =
            lawyerData.society.logoUrl !== null
              ? `${process.env.VUE_APP_API_URL_PUBLIC}${lawyerData.society.logoUrl}`
              : null
        }

        if (
          lawyerData.signatureRequests &&
          lawyerData.signatureRequests.totalItems > 0
        ) {
          lawyerData.signatureRequests.items.reverse()
        }

        commit('SET_LAWYER_DATA', lawyerData)

        return lawyerData
      } catch (error: any) {
        if (
          error.response.data.type !== 'api.error.stripe.checkout.not_found'
        ) {
          showToast(
            'Une erreur est survenue lors de la récupération des données utilisateurs',
            {
              timeout: 5000,
              color: 'error'
            }
          )
        }

        return error.response.data.type
      }
    }
  },
  modules: {}
})
