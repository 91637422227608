<template>
  <footer class="tw-bg-gradient-to-br tw-from-[#04273c] tw-to-[#1f587a]">
    <div
      class="tw-mx-auto tw-flex tw-w-full tw-max-w-[1664px] tw-flex-col tw-gap-12 tw-px-16 tw-py-16 tw-font-['Lexend'] tw-text-white md:tw-px-6"
    >
      <routerLink to="/">
        <vImg :src="require('@images/Logo Blanc.webp')" width="200" />
      </routerLink>
      <div class="grid tw-grid tw-grid-cols-2 tw-gap-12 md:tw-grid-cols-1">
        <div class="tw-flex tw-flex-col tw-gap-4" v-for="menu in footerMenus">
          <p class="tw-text-2xl tw-font-semibold tw-leading-loose">
            {{ menu.title }}
          </p>
          <routerLink
            :to="item.url"
            :title="item.text"
            v-for="item in menu.links"
            class="tw-mr-auto tw-text-gray-400 hover:tw-text-white focus:tw-text-white active:tw-text-white"
            >{{ item.text }}</routerLink
          >
        </div>
      </div>
      <div class="tw-flex tw-justify-end tw-gap-12">
        <a
          :href="link.url"
          v-for="link in socialLinks"
          target="_blank"
          :title="link.name"
        >
          <vImg
            class="tw-flex-initial tw-cursor-pointer tw-p-4"
            :src="require(`@/assets/images/${link.icon}.svg`)"
          />
        </a>
      </div>
      <div
        class="tw-flex tw-justify-end tw-gap-12 tw-text-end xl:tw-grid xl:tw-gap-2"
      >
        <routerLink
          v-for="link in policies"
          class="tw-text-gray-400 hover:tw-text-white focus:tw-text-white active:tw-text-white"
          :to="link.url"
          :title="link.text"
          >{{ link.text }}</routerLink
        >
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
  const footerMenus = [
    {
      title: 'À propos',
      links: [
        { text: 'Tarifs', url: '/pricing' },
        { text: 'À propos', url: '/about' }
      ]
    },
    {
      title: 'Assistance',
      links: [
        { text: 'FAQ', url: '/faq' },
        { text: 'Nous contacter', url: '/contact' }
      ]
    }
  ]
  const socialLinks = [
    { name: 'Facebook', icon: 'Facebook Blanc', url: 'http://facebook.com' },
    { name: 'X', icon: 'X Blanc', url: 'http://example.com' },
    { name: 'Instagram', icon: 'Instagram Blanc', url: 'http://instagram.com' },
    { name: 'LinkedIn', icon: 'LinkedIn Blanc', url: 'http://linkedin.com' }
  ]

  const policies = [
    { text: 'Cookies', url: '#' },
    { text: 'CGU', url: '#' },
    { text: 'Politique de confidentialité', url: '#' },
    { text: 'Mentions légales', url: '#' }
  ]

  function redirectionUrl(url: string) {
    window.open(url, '_blank')
  }
</script>

<style scoped lang="scss">
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1280px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
