// dashboardLawyerRoutes.ts
export const dashboardLawyerRoutes = [
  {
    path: '/dashboard/lawyer',
    name: 'dashboard-lawyer',
    children: [
      {
        path: 'home',
        name: 'dashboard-lawyer-home',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerHomeView.vue')
      },
      {
        path: 'account',
        name: 'dashboard-lawyer-account',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerAccountView.vue')
      },
      {
        path: 'support',
        name: 'dashboard-lawyer-support',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerSupportView.vue')
      },
      {
        path: 'products',
        name: 'dashboard-lawyer-products',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerProductsView.vue')
      },
      {
        path: 'agreement',
        name: 'dashboard-lawyer-agreement',
        component: () =>
          import(
            '@/views/dashboard-lawyer/DashboardLawyerAgreementPreviewView.vue'
          )
      },
      {
        path: 'agreement/create',
        name: 'dashboard-lawyer-create-agreement',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerAgreementView.vue')
      },
      {
        path: 'agreements/:id/edit',
        name: 'dashboard-lawyer-edit-agreement',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerAgreementView.vue')
      },
      {
        path: 'agreements/:id',
        name: 'dashboard-lawyer-agreement-detail',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerAgreementPDFView.vue')
      },
      {
        path: 'customers',
        name: 'dashboard-lawyer-customers',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerCustomersView.vue')
      },
      {
        path: 'customers/:id',
        name: 'dashboard-lawyer-customer-detail',
        component: () =>
          import(
            '@/views/dashboard-lawyer/DashboardLawyerCustomerDetailsView.vue'
          )
      },
      {
        path: 'success-checkout',
        name: 'dashboard-lawyer-success-checkout',
        component: () =>
          import(
            '@/views/dashboard-lawyer/DashboardLawyerSuccessCheckoutView.vue'
          )
      },
      {
        path: 'account-validation',
        name: 'dashboard-lawyer-account-validation',
        component: () =>
          import(
            '@/views/dashboard-lawyer/DashboardLawyerAccountValidationView.vue'
          )
      },
      {
        path: 'invoices',
        name: 'dashboard-lawyer-invoices',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerInvoicesView.vue')
      },
      {
        path: 'invoice/create',
        name: 'dashboard-lawyer-create-invoice',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerInvoiceView.vue')
      },
      {
        path: 'invoices/:id',
        name: 'dashboard-lawyer-invoice-detail',
        component: () =>
          import(
            '@/views/dashboard-lawyer/DashboardLawyerInvoiceDetailView.vue'
          )
      },
      {
        path: 'agreement/create',
        name: 'dashboard-lawyer-agreement-create',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerAgreementView.vue')
      },
      {
        path: 'agreement/edit/:id',
        name: 'dashboard-lawyer-agreement-edit',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerAgreementView.vue')
      },
      {
        path: 'agreement',
        name: 'dashboard-lawyer-agreement',
        component: () =>
          import(
            '@/views/dashboard-lawyer/DashboardLawyerAgreementPreviewView.vue'
          )
      },
      {
        path: 'agreements',
        name: 'dashboard-lawyer-agreements',
        component: () =>
          import('@/views/dashboard-lawyer/DashboardLawyerAgreementsView.vue')
      },
      {
        path: ':pathMatch(.*)*',
        name: 'dashboard-lawyer-not-found',
        redirect: { name: 'dashboard-lawyer-home' }
      }
    ]
  }
]
