<template>
  <aside
    class="tw-flex tw-w-[256px] tw-min-w-[256px] tw-flex-col tw-bg-dashboard-sidebar"
    v-if="windowWidth >= 1080"
  >
    <img
      v-if="societyLogoUrl !== null && societyLogoUrl !== '' && !isLoading"
      :width="300"
      :height="134"
      class="tw-mx-auto tw-mb-4 tw-mt-10 tw-h-20 tw-w-20 tw-rounded-full tw-object-contain tw-shadow-sm tw-shadow-gray-600"
      :src="societyLogoUrl"
    />
    <div
      v-else
      class="tw-mx-auto tw-mb-4 tw-mt-10 tw-grid tw-h-20 tw-w-20 tw-place-content-center tw-rounded-full tw-border-2 tw-border-solid tw-border-gray-600"
    >
      <i class="fa-regular fa-user tw-text-2xl tw-text-text-color"></i>
    </div>
    <p
      class="tw-mb-10 tw-break-words tw-px-4 tw-text-center tw-text-xl tw-text-text-color"
    >
      <span v-if="!isLoading">{{ societyName }}</span>
      <vSkeletonLoader
        v-else
        class="tw-rounded-base tw-mx-auto tw-h-6 tw-w-28"
      />
    </p>
    <vList
      class="tw-relative tw-bg-transparent tw-py-4"
      :data-active="activeIndex"
    >
      <span class="glider"></span>

      <vListItem
        v-for="(item, index) in itemsMenu"
        :key="index"
        :value="index"
        v-model="activeIndex"
        :disabled="item.disabled"
        :to="item.to"
        @click="
          item.click && item.click(),
            item.click === undefined && setActiveIndex(index)
        "
        :ripple="false"
        class="tw-bg-transparent tw-text-text-color"
      >
        <template v-slot:default>
          <span
            class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-pl-6"
          >
            <i
              :class="`tw-w-5 tw-text-center tw-transition-[color] tw-duration-300
                ${item.icon} ${
                  Number(activeIndex) === index
                    ? 'tw-text-primary-color'
                    : 'tw-text-text-color'
                }`"
            ></i>
            <span
              class="tw-ml-4"
              :class="`tw-transition-[color] tw-duration-300
                ${activeIndex === index ? 'tw-text-primary-color' : 'tw-text-text-color'}
              `"
              >{{ item.title }}</span
            >
            <span v-if="item.loading" class="ml-auto">
              <vProgressCircular
                indeterminate
                color="primary"
                size="20"
                width="2"
              />
            </span>
          </span>
        </template>
      </vListItem>
    </vList>
    <vBtn
      color="primary"
      to="/dashboard/lawyer/agreement/create"
      :disabled="!isProductDefined"
      class="tw-rounded-base tw-mx-auto tw-mb-6 tw-ml-auto tw-mt-auto tw-h-auto tw-w-fit tw-px-4 tw-py-3 tw-normal-case"
    >
      <span>Nouvelle convention</span>
      <i class="fa-regular fa-plus tw-ml-2"></i>
    </vBtn>
  </aside>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useResize } from '@/composables/useResize'
  import { useStore } from 'vuex'
  import { onBeforeMount } from 'vue'
  import { useAxios } from '@/composables/useAxios'
  import { useRoute } from 'vue-router'
  import { useRouter } from 'vue-router'
  import { cp } from 'fs'

  //Interfaces
  interface Item {
    icon: string
    title: string
    disabled?: boolean
    to?: string
    loading?: boolean
    click?: () => void
  }

  //Composables
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const { apiClient } = useAxios()
  const windowWidth = useResize().windowWidth

  //Variables

  const isLoading = ref(true)
  const societyLogoUrl = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.society !== null &&
      store.state.lawyerData.society.logoUrl !== null
      ? store.state.lawyerData.society.logoUrl
      : null
  })
  const societyName = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.society !== null &&
      store.state.lawyerData.society.name !== null
      ? store.state.lawyerData.society.name
      : null
  })

  const isAccountLoading = ref(false)
  const activeIndex = ref(0)
  const isProductDefined = computed(
    () => store.state.lawyerData?.stripe.isProductDefined
  )
  const itemsMenu = computed<Item[]>(() => [
    {
      title: 'Tableau de bord',
      icon: 'fa-sharp fa-regular fa-table-columns',
      to: '/dashboard/lawyer/home',
      disabled: !isProductDefined.value
    },
    {
      title: 'Conventions',
      icon: 'fa-regular fa-file-pen',
      to: '/dashboard/lawyer/agreements',
      disabled: !isProductDefined.value
    },
    {
      title: 'Clients',
      icon: 'fa-regular fa-users',
      to: '/dashboard/lawyer/customers',
      disabled: !isProductDefined.value
    },
    {
      title: 'Facturation',
      icon: 'fa-sharp fa-regular fa-file-invoice-dollar',
      disabled: !isProductDefined.value,
      to: '/dashboard/lawyer/invoices'
    },
    {
      title: 'Prestations',
      icon: 'fa-regular fa-handshake',
      to: '/dashboard/lawyer/products'
    },
    {
      title: 'Revenus',
      icon: 'fa-regular fa-euro-sign',
      to: '',
      loading: isAccountLoading.value,
      disabled: !isProductDefined.value,
      click: () => {
        redirectAccount()
      }
    }
  ])

  //Hooks
  onBeforeMount(async () => {
    if (societyLogoUrl.value !== null || societyLogoUrl.value !== '') {
      isLoading.value = false
    }

    updateActiveIndex(route.path)
  })

  watch(
    () => route.path,
    (newPath) => {
      updateActiveIndex(newPath)
    }
  )

  function pathMatches(itemPath: string, newPath: string) {
    const basePaths = [
      '/dashboard/lawyer/agreement',
      '/dashboard/lawyer/customer',
      '/dashboard/lawyer/invoice',
      '/dashboard/lawyer/home',
      '/dashboard/lawyer/products'
    ]

    // Vérifie si le newPath commence par un des basePath
    return basePaths.some(
      (basePath) =>
        newPath.startsWith(basePath) && itemPath.startsWith(basePath)
    )
  }

  function updateActiveIndex(newPath: string) {
    activeIndex.value = itemsMenu.value.findIndex((item) =>
      pathMatches(item.to as string, newPath)
    )
  }

  //Functions
  function setActiveIndex(index: number) {
    activeIndex.value = index
  }

  async function redirectAccount() {
    isAccountLoading.value = true

    try {
      const response = await apiClient.get(
        '/dashboard/society/stripe/login-link',
        {
          params: {
            access_token: localStorage.getItem('access_token') as string
          }
        }
      )

      if (!response || !response.data || !response.data.url) {
        throw new Error('Erreur lors de la récupération du lien de connexion')
      }

      window.open(response.data.url, '_blank')
    } catch (error) {
      console.error(
        'Erreur lors de la récupération du lien de connexion:',
        error
      )
    } finally {
      isAccountLoading.value = false
    }
  }
</script>

<style scoped lang="scss">
  .tw-bg-secondary-color {
    background: linear-gradient(203deg, #04273c 0%, #1f587a 100%);
  }
  .glider {
    --border-radius: 1rem;
    --item-height: 48px;
    --background-glider: #ffffff;

    position: absolute;
    width: calc(100% - 20px);
    height: 48px;
    border-radius: 100vw 0 0 100vw;
    left: 20px;
    background: var(--background-glider);
    translate: 0 0;
    transition: translate 0.3s ease;
    color: var(--secondary-color) !important;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: var(--border-radius);
      height: var(--border-radius);
      right: 0;
    }

    &:before {
      border-radius: 0 0 100vw 0;
      top: calc(var(--border-radius) * -1);
      box-shadow: 11px 11px 0 10px var(--background-glider);
    }

    &:after {
      border-radius: 0 100vw 0 0;
      bottom: calc(var(--border-radius) * -1);
      left: initial;
      top: initial;
      opacity: 1;
      box-shadow: 11px -11px 0 10px var(--background-glider);
      border: none;
    }

    [data-active='-1'] & {
      display: none;
    }

    [data-active='0'] & {
      translate: 0 0;
    }

    [data-active='1'] & {
      translate: 0 calc(var(--item-height) * 1);
    }

    [data-active='2'] & {
      translate: 0 calc(var(--item-height) * 2);
    }

    [data-active='3'] & {
      translate: 0 calc(var(--item-height) * 3);
    }

    [data-active='4'] & {
      translate: 0 calc(var(--item-height) * 4);
    }

    [data-active='5'] & {
      translate: 0 calc(var(--item-height) * 5);
    }
  }

  :deep(.v-list-item) {
    .v-list-item__overlay {
      background: none;
    }
  }
</style>
