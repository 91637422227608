<template>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Lexend:300,400,500,700&display=swap"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
  />

  <vApp>
    <HeaderComponent v-if="!isDashboard && !isCheckout" />
    <DashboardLawyerHeaderComponent
      v-if="isLogged && isDashboard && !isCheckout"
    />
    <div
      class="tw-flex tw-h-full tw-max-w-full tw-flex-row"
      v-if="isLogged && isDashboard && !isCheckout"
    >
      <DashboardLawyerSidebarComponent
        v-if="
          !route.path.includes('/dashboard/lawyer/account-validation') &&
          !route.path.includes('/dashboard/lawyer/success-checkout')
        "
      />
      <div
        class="tw-grid tw-flex-1 tw-place-content-center tw-bg-alt-white-color"
        v-if="isDashboardLoading"
      >
        <vProgressCircular
          indeterminate
          size="50"
          width="2"
          color="primary"
        ></vProgressCircular>
      </div>
      <vMain
        :class="
          isDashboardLoading
            ? 'tw-w-0 tw-max-w-0 tw-opacity-0 tw-transition-none'
            : 'tw-flex-1'
        "
      >
        <routerView />
      </vMain>
    </div>
    <vMain v-else>
      <routerView />
    </vMain>
    <FooterComponent v-if="!isCheckout && !isDashboard" />
  </vApp>
</template>

<script setup lang="ts">
  import DashboardLawyerHeaderComponent from './components/dashboard-lawyer/DashboardLawyerHeaderComponent.vue'
  import HeaderComponent from './components/global/HeaderComponent.vue'
  import DashboardLawyerSidebarComponent from './components/dashboard-lawyer/DashboardLawyerSidebarComponent.vue'
  import { onMounted, onUnmounted, onBeforeMount, computed, ref } from 'vue'
  import FooterComponent from './components/global/FooterComponent.vue'
  import { useStore } from 'vuex'
  import { changeMetas } from './services/setMetas'
  import { useRoute } from 'vue-router'

  const store = useStore()
  const isLogged = computed(() => store.getters.getIsLogged)
  const isCheckout = computed(() => store.getters.getIsCheckout)
  const isDashboard = computed(() => store.getters.getIsDashboard)
  const isDashboardLoading = computed(() => store.getters.getIsDashboardLoading)
  const route = useRoute()

  const location = ref('')

  const updateFullPath = () => {
    store.commit('SET_FULL_PATH', location.value)
    changeMetas(location.value)
  }

  const updateScreenWidth = () => {
    store.commit('SET_SCREEN_WIDTH', window.innerWidth)
  }

  onBeforeMount(() => {
    store.commit(
      'SET_IS_LOGGED',
      localStorage.getItem('access_token') ? true : false
    )
    store.commit('SET_IS_DASHBOARD', route.path.includes('/dashboard/lawyer/'))
    store.commit('SET_IS_CHECKOUT', route.path.includes('/checkout'))

    changeMetas(window.location.pathname)
  })

  onMounted(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)
    window.addEventListener('popstate', () => {
      location.value = window.location.pathname
      updateFullPath()
    })
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateScreenWidth)
    window.removeEventListener('popstate', () => {
      location.value = window.location.pathname
      updateFullPath()
    })
  })
</script>
