<template>
  <vAppBar class="tw-relative tw-bg-white lg:tw-fixed">
    <vToolbarTitle>
      <vImg width="150" class="" :src="require('@images/Logo.webp')"></vImg>
    </vToolbarTitle>

    <!-- <vBtn
      color="secondary"
      class="tw-text-primary-text-color tw-h-full tw-rounded-none"
    >
      <i class="fa-regular fa-bell tw-text-lg"></i>
      <span
        class="tw-absolute tw-right-3 tw-top-4 tw-grid tw-h-[17px] tw-w-[17px] tw-place-items-center tw-rounded-full tw-bg-red-500 tw-text-[10px] tw-text-xs tw-font-bold tw-leading-none tw-text-red-100"
      >
        3
      </span>
    </vBtn> -->

    <vBtn
      color="secondary"
      class="tw-text-primary-text-color tw-mr-0 tw-h-full tw-rounded-none"
      v-if="screenWidth >= 1080"
      @click="menuOpener = !menuOpener"
    >
      <span class="tw-normal-case">{{ lawyerName }}</span>
      <img
        v-if="
          (lawyerLogoUrl !== null && lawyerLogoUrl !== '') ||
          (lawyerGoogleAvatarUrl !== null && lawyerGoogleAvatarUrl !== '')
        "
        width="300"
        class="tw-mx-auto tw-ml-4 tw-h-10 tw-w-10 tw-rounded-full tw-object-cover"
        :src="
          lawyerLogoUrl !== ''
            ? lawyerLogoUrl
            : lawyerGoogleAvatarUrl !== ''
              ? lawyerGoogleAvatarUrl
              : ''
        "
      />
      <div
        v-else
        class="tw-border-primary-text-color tw-ml-4 tw-grid tw-h-10 tw-w-10 tw-place-content-center tw-rounded-full tw-border-2 tw-border-solid"
      >
        <i
          class="fa-regular fa-user tw-text-primary-text-color tw-text-base"
        ></i>
      </div>
    </vBtn>
    <vBtn
      @click="
        (isDisabledButton = !isDisabledButton), (drawerOpener = !drawerOpener)
      "
      class="tw-text-primary-text-color tw-mr-0 tw-h-full tw-rounded-none"
      v-else
    >
      <i v-if="isDisabledButton" class="fa-regular fa-bars"></i>
      <i v-else class="fa-regular fa-arrow-left"></i>
    </vBtn>

    <vMenu
      transition="slide-y-transition"
      class="tw-right-0"
      id="headerDrawer"
      v-model="menuOpener"
    >
      <div v-if="screenWidth >= 1080">
        <vList>
          <vListItem
            v-for="(item, index) in itemsMenuDesktop"
            :key="index"
            :value="index"
            :to="
              !route.path.includes('/dashboard/lawyer/account-validation') &&
              !route.path.includes('/dashboard/lawyer/success-checkout')
                ? item.to
                : ''
            "
            :disabled="item.disabled && item.disabled === true"
            @click="item.click ? item.click() : null"
          >
            <span class="tw-flex tw-flex-row tw-justify-start tw-gap-2">
              <span class="tw-w-5 tw-text-center">
                <i v-if="item.icon !== ''" :class="item.icon"></i>
              </span>
              <span>
                {{ item.title }}
              </span>
            </span>
          </vListItem>
        </vList>
      </div>
    </vMenu>
  </vAppBar>

  <vNavigationDrawer
    v-model="drawerOpener"
    temporary
    location="right"
    style="width: 100vw"
    id="headerDrawer"
  >
    <div
      class="tw-flex tw-h-full tw-w-full tw-flex-col tw-bg-dashboard-sidebar"
    >
      <div class="tw-overflow-auto tw-pt-10">
        <img
          v-if="societyLogoUrl !== '' && societyLogoUrl !== null"
          width="300"
          class="tw-mx-auto tw-h-20 tw-w-20 tw-rounded-full tw-object-cover"
          :src="societyLogoUrl"
        />
        <div
          v-else
          class="tw-mx-auto tw-mb-4 tw-mt-10 tw-grid tw-h-20 tw-w-20 tw-place-content-center tw-rounded-full tw-border-2 tw-border-solid tw-border-gray-600 tw-bg-primary-color"
        >
          <i class="fa-regular fa-user tw-text-2xl tw-text-text-color"></i>
        </div>
        <p
          class="tw-mb-10 tw-mt-4 tw-break-words tw-text-center tw-text-2xl tw-text-text-color"
        >
          {{ societyName }}
        </p>
        <vList class="tw-bg-transparent tw-text-text-color">
          <template v-for="(item, index) in itemsMenuMobile" :key="index">
            <vListItem
              v-if="item.title !== 'Se déconnecter'"
              :value="item.title"
              :prepend-icon="item.icon"
              :title="item.title"
              :to="item.to"
              :disabled="
                route.path.includes('/dashboard/lawyer/account-validation') ||
                route.path.includes('/dashboard/lawyer/success-checkout')
              "
              :class="{
                'tw-text-primary-color': activeIndex === index
              }"
            >
            </vListItem>
          </template>
        </vList>
        <hr class="tw-mx-4 tw-my-2 tw-border-t tw-border-gray-300" />
        <vList class="tw-bg-transparent tw-text-text-color">
          <template v-for="(item, index) in itemsMenuDesktop" :key="index">
            <vListItem
              v-if="
                item.title !== 'Se déconnecter' && item.title !== 'Mon compte'
              "
              :value="index"
              :prepend-icon="item.icon"
              :title="item.title"
              :to="
                !route.path.includes('/dashboard/lawyer/account-validation') &&
                !route.path.includes('/dashboard/lawyer/success-checkout')
                  ? item.to
                  : ''
              "
              :disabled="item.disabled && item.disabled === true"
              @click="item.click ? item.click() : null"
            >
            </vListItem>
          </template>
        </vList>
      </div>
      <div class="tw-relative tw-mt-auto">
        <div class="tw-h-[6px] tw-rotate-180 tw-shadow-md"></div>
        <div
          class="tw-mb-2 tw-mt-1 tw-flex tw-w-full tw-flex-row tw-items-stretch tw-justify-between tw-gap-4 tw-px-2"
        >
          <routerLink
            class="tw-flex tw-h-auto tw-w-auto tw-max-w-[calc(100%-80px)] tw-grow tw-flex-row tw-items-center tw-justify-start tw-gap-2 tw-overflow-hidden tw-rounded-none tw-bg-transparent tw-p-4 tw-text-text-color"
            :to="
              !route.path.includes('/dashboard/lawyer/account-validation') &&
              !route.path.includes('/dashboard/lawyer/success-checkout')
                ? '/dashboard/lawyer/account'
                : ''
            "
          >
            <img
              v-if="
                (lawyerLogoUrl !== '' && lawyerLogoUrl !== null) ||
                (lawyerGoogleAvatarUrl !== '' && lawyerGoogleAvatarUrl !== null)
              "
              width="300"
              class="tw-mr-4 tw-h-10 tw-w-10 tw-rounded-full tw-object-cover"
              :src="
                lawyerLogoUrl !== ''
                  ? lawyerLogoUrl
                  : lawyerGoogleAvatarUrl !== ''
                    ? lawyerGoogleAvatarUrl
                    : ''
              "
            />
            <div
              v-else
              class="tw-mr-4 tw-grid tw-h-10 tw-w-10 tw-place-content-center tw-rounded-full tw-border-2 tw-border-solid tw-border-gray-900"
            >
              <i class="fa-regular fa-user tw-text-base tw-text-text-color"></i>
            </div>
            <p
              class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-normal-case tw-text-text-color"
            >
              {{ lawyerName }}
            </p>
          </routerLink>
          <vBtn
            class="tw-absolute tw-right-0 tw-top-1/2 tw-grid tw-h-auto tw-w-20 -tw-translate-y-1/2 tw-place-content-center tw-rounded-none tw-bg-transparent tw-p-4 tw-text-text-color"
            variant="flat"
            @click="logout"
          >
            <i class="fa-regular fa-arrow-right-from-bracket tw-text-2xl"></i>
          </vBtn>
        </div>
      </div>
    </div>
  </vNavigationDrawer>
</template>

<script setup lang="ts">
  import { onBeforeMount, ref, watch } from 'vue'
  import { useAxios } from '@/composables/useAxios'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'

  //Composables
  const { logout } = useAxios()
  const route = useRoute()
  const store = useStore()
  const router = useRouter()

  //Variables
  const screenWidth = computed<number>(() => store.getters.getScreenWidth)
  const drawerOpener = ref(false)
  const menuOpener = ref(false)

  const itemsMenuDesktop = ref([
    {
      title: 'Mon compte',
      icon: 'fa-regular fa-user',
      to: '/dashboard/lawyer/account',
      disabled:
        route.path.includes('/dashboard/lawyer/account-validation') ||
        route.path.includes('/dashboard/lawyer/success-checkout')
    },
    {
      title: 'Support',
      icon: 'fa-regular fa-headset',
      to: '/dashboard/lawyer/support'
    },
    // {
    //   title: 'Inviter un confrère',
    //   icon: 'fa-regular fa-user-plus',
    //   disabled:
    //     route.path.includes('/dashboard/lawyer/account-validation') ||
    //     route.path.includes('/dashboard/lawyer/success-checkout')
    // },
    // {
    //   title: 'Evaluez-nous',
    //   icon: 'fa-regular fa-star',
    //   disabled:
    //     route.path.includes('/dashboard/lawyer/account-validation') ||
    //     route.path.includes('/dashboard/lawyer/success-checkout')
    // },
    {
      title: 'Se déconnecter',
      icon: 'fa-regular fa-arrow-right-from-bracket',
      click: logout
    }
  ])

  const itemsMenuMobile = ref([
    {
      title: 'Tableau de bord',
      icon: 'fa-sharp fa-regular fa-table-columns',
      to: '/dashboard/lawyer/home'
    },
    {
      title: 'Conventions',
      icon: 'fa-regular fa-file-pen',
      to: '/dashboard/lawyer/agreements'
    },
    {
      title: 'Clients',
      icon: 'fa-regular fa-users',
      to: '/dashboard/lawyer/customers'
    },
    {
      title: 'Facturation',
      icon: 'fa-sharp fa-regular fa-file-invoice-dollar',
      to: '/dashboard/lawyer/invoices'
    },
    {
      title: 'Prestations',
      icon: 'fa-regular fa-handshake',
      to: '/dashboard/lawyer/products'
    }
  ])

  const isDisabledButton = ref(true)
  let activeIndex = ref(0)

  const lawyerLogoUrl = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.details.logoUrl !== null
      ? store.state.lawyerData.details.logoUrl
      : ''
  })

  const lawyerName = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.firstName !== null &&
      store.state.lawyerData.lastName !== null
      ? `${store.state.lawyerData.firstName} ${store.state.lawyerData.lastName}`
      : 'Mon compte'
  })

  const lawyerGoogleAvatarUrl = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.details.googleAvatar !== ''
      ? store.state.lawyerData.details.googleAvatar
      : ''
  })

  const societyLogoUrl = computed(() => {
    return store.state.lawyerData &&
      store.state.lawyerData.society !== undefined &&
      store.state.lawyerData.society.logoUrl !== null
      ? store.state.lawyerData.society.logoUrl
      : ''
  })

  const societyName = computed(() => {
    return store.state.lawyerData &&
      store.state.lawyerData.society !== undefined
      ? store.state.lawyerData.society.name
      : ''
  })

  onBeforeMount(async () => {
    await store.dispatch('getLawyerData')
    updateActiveIndex(route.path)
  })

  watch(
    () => route.path,
    (newPath) => {
      updateActiveIndex(newPath)
    }
  )

  //Functions
  function updateActiveIndex(newPath: string) {
    itemsMenuDesktop.value.forEach((item, index) => {
      if (
        item.to === newPath ||
        (item.to === '/dashboard/lawyer/agreements' &&
          [
            '/dashboard/lawyer/agreements',
            '/dashboard/lawyer/agreement',
            '/dashboard/lawyer/agreement/:id',
            '/dashboard/lawyer/agreement/create'
          ].includes(newPath))
      ) {
        activeIndex.value = index
      }
    })
  }
</script>

<style lang="scss">
  @mixin mu {
    @media (min-width: 1081px) {
      @content;
    }
  }

  @include mu {
    main {
      padding-top: 0 !important;
    }
  }
</style>

<style scoped lang="scss">
  // Mixin Mobile
  @mixin md {
    @media (max-width: 1080px) {
      @content;
    }
  }

  // Mixin Desktop
  @mixin mu {
    @media (min-width: 1081px) {
      @content;
    }
  }

  .tw-bg-secondary-color {
    background: linear-gradient(203deg, #04273c 0%, #1f587a 100%);
  }

  :deep(.v-overlay__content) {
    @include md {
      top: var(--header-dashboard-height) !important;
      max-width: initial !important;
      width: 100vw !important;
      height: calc(100svh - var(--header-dashboard-height)) !important;
      left: 0 !important;
      right: 0 !important;
      border-radius: 0;
    }

    @include mu {
      top: calc(var(--header-dashboard-height) + 1rem) !important;
      right: 1rem !important;
    }

    > div > .v-list {
      padding-block: 0;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .tw-rounded-none.v-btn {
    border-radius: 0 !important;
  }
</style>
