<template>
  <header class="tw-inline-flex tw-h-16 tw-items-center tw-bg-white-color">
    <div
      class="tw-mx-auto tw-inline-flex tw-w-full tw-max-w-[1664px] tw-items-center tw-px-16 lg:tw-px-0"
    >
      <routerLink
        to="/"
        title="Accueil"
        class="tw-flex tw-h-full tw-items-center tw-justify-center tw-pl-6"
      >
        <vImg :src="require('@images/Logo.webp')" width="150" />
      </routerLink>
      <nav class="tw-ml-auto tw-text-cyan-900 lg:tw-hidden">
        <vBtn
          v-for="item in desktopMenu"
          class="tw-mx-2 tw-rounded-lg tw-capitalize tw-text-gray-500 hover:tw-text-cyan-900 focus:tw-text-cyan-900 active:tw-text-cyan-900"
          variant="text"
          :ripple="false"
          :to="item.url"
        >
          {{ item.title }}
        </vBtn>
      </nav>
      <div v-if="lawyerName === null" class="tw-ml-auto lg:tw-hidden">
        <vBtn
          class="tw-mx-2 tw-rounded-lg tw-border-2 tw-border-solid tw-border-primary-color tw-capitalize"
          color="primary"
          variant="outlined"
          elevation="0"
          to="/login"
        >
          Connexion
        </vBtn>
        <vBtn
          class="tw-mx-2 tw-rounded-lg tw-capitalize"
          color="primary"
          elevation="0"
          to="/register"
        >
          Inscription
        </vBtn>
      </div>
      <div v-else class="tw-ml-auto lg:tw-hidden">
        <vBtn
          color="secondary"
          variant="text"
          class="tw-text-primary-text-color tw-mr-0 tw-h-full tw-py-2"
          v-if="screenWidth >= 1080"
          @click="menuOpener = !menuOpener"
        >
          <span class="tw-normal-case">{{ lawyerName }}</span>
          <img
            v-if="
              (lawyerLogoUrl !== null && lawyerLogoUrl !== '') ||
              (lawyerGoogleAvatarUrl !== null && lawyerGoogleAvatarUrl !== '')
            "
            width="300"
            class="tw-mx-auto tw-ml-4 tw-h-10 tw-w-10 tw-rounded-full tw-object-cover"
            :src="
              lawyerLogoUrl !== ''
                ? lawyerLogoUrl
                : lawyerGoogleAvatarUrl !== ''
                  ? lawyerGoogleAvatarUrl
                  : ''
            "
          />
          <div
            v-else
            class="tw-border-primary-text-color tw-ml-4 tw-grid tw-h-10 tw-w-10 tw-place-content-center tw-rounded-full tw-border-2 tw-border-solid"
          >
            <i
              class="fa-regular fa-user tw-text-primary-text-color tw-text-base"
            ></i>
          </div>
        </vBtn>

        <vMenu
          transition="slide-y-transition"
          id="menuLogged"
          v-model="menuOpener"
        >
          <vList>
            <vListItem
              v-for="(item, index) in itemsMenuDesktop"
              :key="index"
              :value="index"
              :to="
                !route.path.includes('/dashboard/lawyer/account-validation') &&
                !route.path.includes('/dashboard/lawyer/success-checkout')
                  ? item.to
                  : ''
              "
              :disabled="item.disabled && item.disabled === true"
              @click="item.click ? item.click() : null"
            >
              <span class="tw-flex tw-flex-row tw-justify-start tw-gap-2">
                <span class="tw-w-5 tw-text-center">
                  <i v-if="item.icon !== ''" :class="item.icon"></i>
                </span>
                <span>
                  {{ item.title }}
                </span>
              </span>
            </vListItem>
          </vList>
        </vMenu>
      </div>
      <div class="tw-ml-auto tw-hidden tw-h-full lg:tw-block">
        <vBtn
          class="drawer-opener tw-h-full tw-rounded-none"
          variant="text"
          @click="toggleDrawer()"
        >
          <i
            :class="
              isDrawerOpen ? 'fa-regular fa-bars' : 'fa-regular fa-arrow-left'
            "
            class="fa-regular fa-bars tw-px-4 tw-text-3xl tw-text-cyan-900"
          ></i>
        </vBtn>
      </div>
    </div>
  </header>
  <vNavigationDrawer
    v-model="isDrawerOpen"
    temporary
    location="right"
    style="width: 100vw"
    class="tw-top-[var(--header-front-height)] tw-h-header-front-screen"
    id="headerDrawer"
  >
    <div
      class="tw-flex tw-h-full tw-justify-center tw-px-0 tw-pt-8 tw-text-cyan-900"
    >
      <nav
        class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-start tw-gap-4"
      >
        <!--Do vList item with itemsDekstop -->
        <div class="tw-w-full tw-flex-1 tw-overflow-y-auto">
          <vList>
            <vListItem
              v-for="(item, index) in desktopMenu"
              :key="index"
              :value="index"
              :to="item.url"
            >
              <span class="tw-flex tw-flex-row tw-justify-start tw-gap-2">
                <span class="tw-w-5 tw-text-center">
                  <i v-if="item.icon !== ''" :class="item.icon"></i>
                </span>
                <span>
                  {{ item.title }}
                </span>
              </span>
            </vListItem>
          </vList>
          <div v-if="lawyerName !== null" class="tw-w-full">
            <div class="tw-mx-8 tw-my-2">
              <vDivider class="tw-border-dark-grey"></vDivider>
            </div>
            <vList>
              <vListItem
                v-for="(item, index) in itemsMenuDesktop"
                :key="index"
                :value="index"
                :to="
                  !route.path.includes(
                    '/dashboard/lawyer/account-validation'
                  ) &&
                  !route.path.includes('/dashboard/lawyer/success-checkout')
                    ? item.to
                    : ''
                "
                :disabled="item.disabled && item.disabled === true"
                @click="item.click ? item.click() : null"
              >
                <span class="tw-flex tw-flex-row tw-justify-start tw-gap-2">
                  <span class="tw-w-5 tw-text-center">
                    <i v-if="item.icon !== ''" :class="item.icon"></i>
                  </span>
                  <span>
                    {{ item.title }}
                  </span>
                </span>
              </vListItem>
            </vList>
          </div>
        </div>
        <div class="tw-relative tw-mt-auto tw-w-full">
          <div class="tw-h-[6px] tw-rotate-180 tw-shadow-md"></div>
          <div v-if="lawyerName === null" class="tw-px-6 tw-pt-2">
            <vBtn
              class="tw-flex tw-w-full tw-rounded-lg tw-border-2 tw-border-solid tw-border-primary-color tw-px-12 tw-py-6 tw-text-lg tw-capitalize sm:tw-text-base"
              color="primary"
              variant="outlined"
              elevation="0"
              to="/login"
            >
              Connexion
            </vBtn>
            <vBtn
              class="tw-my-4 tw-flex tw-w-full tw-rounded-lg tw-px-12 tw-py-6 tw-text-lg tw-capitalize sm:tw-text-base"
              color="primary"
              elevation="0"
              to="/register"
            >
              Inscription
            </vBtn>
          </div>
          <div
            v-else
            class="tw-mb-2 tw-mt-1 tw-flex tw-w-full tw-flex-row tw-items-stretch tw-justify-between tw-gap-4 tw-px-2"
          >
            <routerLink
              class="tw-flex tw-h-auto tw-w-auto tw-max-w-[calc(100%-80px)] tw-grow tw-flex-row tw-items-center tw-justify-start tw-gap-2 tw-overflow-hidden tw-rounded-none tw-bg-transparent tw-p-4 tw-text-text-color"
              :to="
                !route.path.includes('/dashboard/lawyer/account-validation') &&
                !route.path.includes('/dashboard/lawyer/success-checkout')
                  ? '/dashboard/lawyer/account'
                  : ''
              "
            >
              <img
                v-if="
                  (lawyerLogoUrl !== '' && lawyerLogoUrl !== null) ||
                  (lawyerGoogleAvatarUrl !== '' &&
                    lawyerGoogleAvatarUrl !== null)
                "
                width="300"
                class="tw-mr-4 tw-h-10 tw-w-10 tw-rounded-full tw-object-cover"
                :src="
                  lawyerLogoUrl !== ''
                    ? lawyerLogoUrl
                    : lawyerGoogleAvatarUrl !== ''
                      ? lawyerGoogleAvatarUrl
                      : ''
                "
              />
              <div
                v-else
                class="tw-mr-4 tw-grid tw-h-10 tw-w-10 tw-place-content-center tw-rounded-full tw-border-2 tw-border-solid tw-border-gray-900"
              >
                <i
                  class="fa-regular fa-user tw-text-base tw-text-text-color"
                ></i>
              </div>
              <p
                class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-normal-case tw-text-text-color"
              >
                {{ lawyerName }}
              </p>
            </routerLink>
            <vBtn
              class="tw-absolute tw-right-0 tw-top-1/2 tw-grid tw-h-auto tw-w-20 -tw-translate-y-1/2 tw-place-content-center tw-rounded-none tw-bg-transparent tw-p-4 tw-text-text-color"
              variant="flat"
              @click="logout"
            >
              <i class="fa-regular fa-arrow-right-from-bracket tw-text-2xl"></i>
            </vBtn>
          </div>
        </div>
      </nav>
    </div>
  </vNavigationDrawer>
</template>

<script setup lang="ts">
  import { useAxios } from '@/composables/useAxios'
  import { onUnmounted, ref, watchEffect } from 'vue'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'

  //Composables
  const screenWidth = computed<number>(() => store.getters.getScreenWidth)
  const route = useRoute()
  const store = useStore()
  const { logout } = useAxios()
  const menuOpener = ref(false)

  const isProductDefined = computed(
    () => store.state.lawyerData?.stripe.isProductDefined
  )

  //Variables
  const itemsMenuDesktop = ref([
    {
      title: 'Tableau de bord',
      icon: 'fa-sharp fa-regular fa-table-columns',
      to: isProductDefined.value
        ? '/dashboard/lawyer/home'
        : '/dashboard/lawyer/products'
    },
    {
      title: 'Mon compte',
      icon: 'fa-regular fa-user',
      to: '/dashboard/lawyer/account'
    },
    {
      title: 'Support',
      icon: 'fa-regular fa-headset',
      to: '/dashboard/lawyer/support'
    },
    // {
    //   title: 'Inviter un confrère',
    //   icon: 'fa-regular fa-user-plus',
    //   disabled: !isProductDefined.value
    // },
    // {
    //   title: 'Evaluez-nous',
    //   icon: 'fa-regular fa-star',
    //   disabled: !isProductDefined.value
    // },
    {
      title: 'Se déconnecter',
      icon: 'fa-regular fa-arrow-right-from-bracket',
      click: logout
    }
  ])

  const lawyerLogoUrl = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.details.logoUrl !== null
      ? store.state.lawyerData.details.logoUrl
      : ''
  })

  const lawyerName = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.firstName !== null &&
      store.state.lawyerData.lastName !== null
      ? `${store.state.lawyerData.firstName} ${store.state.lawyerData.lastName}`
      : null
  })

  const lawyerGoogleAvatarUrl = computed(() => {
    return store.state.lawyerData !== null &&
      store.state.lawyerData.details.googleAvatar !== ''
      ? store.state.lawyerData.details.googleAvatar
      : ''
  })

  const isDrawerOpen = ref(false)
  const desktopMenu = [
    {
      title: 'Tarifs',
      url: '/pricing',
      icon: 'fa-regular fa-euro-sign'
    },
    {
      title: 'FAQ',
      url: '/faq',
      icon: 'fa-regular fa-question-circle'
    },
    {
      title: 'À propos',
      url: '/about',
      icon: 'fa-regular fa-info-circle'
    },
    {
      title: 'Contact',
      url: '/contact',
      icon: 'fa-regular fa-envelope'
    }
  ]

  onUnmounted(() => {
    document.documentElement.classList.remove('overflow-hidden')
  })

  watchEffect(
    () => {
      isDrawerOpen.value === true
        ? document.documentElement.classList.add('overflow-hidden')
        : document.documentElement.classList.remove('overflow-hidden')
    },
    { flush: 'post' }
  )

  function toggleDrawer() {
    isDrawerOpen.value = !isDrawerOpen.value
    document.documentElement.classList.toggle('overflow-hidden')
  }
</script>

<style scoped lang="scss">
  @mixin md {
    @media (max-width: 1080px) {
      @content;
    }
  }

  @mixin mu {
    @media (min-width: 1081px) {
      @content;
    }
  }

  .drawer-opener.v-btn {
    border-radius: 0 !important;
  }

  #menuLogged :deep(.v-overlay__content) {
    top: calc(var(--header-front-height) + 1rem) !important;
    right: calc(50vw - 832px + 4.5rem);

    > div > .v-list {
      padding-block: 0;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
</style>
