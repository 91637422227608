// src/composables/useAxios.js
import axios from 'axios'
import { useRouter } from 'vue-router'

export function useAxios() {
  const router = useRouter()

  const apiClientConfig = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + process.env.VUE_APP_JWT_SECRET
    },
    withCredentials: true
  }

  const apiClient = axios.create(apiClientConfig)

  apiClient.interceptors.response.use(
    (response) => {
      if (response.data && response.data.message === 'token_invalid') {
        logout()
      }

      return response
    },
    (error) => {
      console.error(error)
      if (error.response && error.response.status === 401) {
        logout()
      }

      if (error.response && error.response.status === 403) {
        window.location.href = '/pricing'
      }

      return Promise.reject(error)
    }
  )

  const logout = async () => {
    localStorage.removeItem('access_token')
    if (router && router.currentRoute?.value.path !== '/login') {
      window.location.href = router.currentRoute?.value.path
    }

    await logoutApi()
  }

  const logoutApi = async () => {
    try {
      await apiClient.get('/logout')
    } catch (error) {
      console.error(error)
    }
  }

  return { apiClient, logout }
}
