import { metaDatas } from '@/locales/fr.meta'

export function changeMetas(to: string) {
  const pathSegments = to.substring(1).split('/')

  let dynamicPath = pathSegments
    .map((segment: string) => (segment.match(/^\d+$/) ? '{ID}' : segment))
    .join('/')

  if (dynamicPath === '') {
    dynamicPath = 'home'

    createSchema({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: `${process.env.VUE_APP_BASE_URL}`,
      name: metaDatas.home.title,
      description: metaDatas.home.description
    })

    createSchema({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: `${process.env.VUE_APP_BASE_URL}`,
      logo: require('@/assets/images/Logo.webp'),
      sameAs: [
        'https://www.facebook.com/clicknzen',
        'https://twitter.com/clicknzen',
        'https://www.linkedin.com/company/clicknzen'
      ],
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+33 123456789',
          contactType: 'customer service'
        }
      ],
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Paris',
        addressRegion: 'Île-de-France',
        postalCode: '75000',
        streetAddress: '5 rue de la Paix'
      },
      legalName: "Click N'Zen Avocat",
      description: metaDatas.home.description,
      name: metaDatas.home.title,
      foundingDate: '2024',
      founder: "Click N'Zen Avocat",
      image: require('@/assets/images/Logo.webp'),
      email: 'contact@clicknzen.fr'
    })
  }

  const metas: { title: string; description: string; index: boolean } =
    metaDatas[dynamicPath as keyof typeof metaDatas] || {
      title: '',
      description: '',
      index: false
    }

  document.title = metas.title

  updateMetaTag('description', metas.description)
  updateMetaTag('robots', metas.index ? 'index,follow' : 'noindex,nofollow')
  updateCanonical(to)
  removeSchemas()
  createBreadcrumb(to)
}

export function removeSchemas() {
  const schemas = document.querySelectorAll(
    'script[type="application/ld+json"]'
  )

  schemas.forEach((schema) => schema.remove())
}

export function updateCanonical(path: string) {
  const link = document.querySelector('link[rel="canonical"]')

  if (link) {
    link.setAttribute('href', `${process.env.VUE_APP_BASE_URL}${path}`)
  }
}

function createBreadcrumb(path: string) {
  const title =
    metaDatas[path.slice(1, path.length) as keyof typeof metaDatas]?.title ??
    metaDatas['404'].title

  const script = document.createElement('script')
  script.type = 'application/ld+json'

  script.innerHTML = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: metaDatas.home.title,
        item: `${process.env.VUE_APP_BASE_URL}`
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: title,
        item: `${process.env.VUE_APP_BASE_URL}${path}`
      }
    ]
  })

  document.head.appendChild(script)
}

export function createSchema(schema: Object) {
  const script = document.createElement('script')
  script.type = 'application/ld+json'
  script.innerHTML = JSON.stringify(schema)
  document.head.appendChild(script)
}

function updateMetaTag(name: string, content: string) {
  const metaTag = document.querySelector(`meta[name="${name}"]`)

  if (metaTag) {
    metaTag.setAttribute('content', content)
  }
}
